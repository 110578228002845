.first-box{padding:10px;background:#9C0;}
.second-box{padding:10px; background:#39F;}
.third-box{padding:10px;background:#F66;}
.fourth-box{padding:10px;background:#6CC;}

.first-box:hover{background:rgb(166, 221, 0);}
.second-box:hover{background:rgb(71, 163, 255);}
.third-box:hover{background:rgb(255, 128, 128);}
.fourth-box:hover{background:rgb(112, 221, 221);}
.call_icons h3{color: rgb(255, 255, 255);}
.call_icons h4{color:white;font-weight: 600;}