@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Outfit&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Cairo&family=Open+Sans:wght@400;600;800&display=swap');

body {
  /* font-family: "Roboto", sans-serif; */
  /* background: #f9ffac61; */

  font-family: 'Cairo', sans-serif;
}
h4{
  font-family: 'Outfit', sans-serif;
  font-weight: 600;
}
:root {
  --main-color: #986f2e;
  --second-color: hsla(263, 100%, 48%, 0.438);
  --second-fonts: "Montserrat", sans-serif;
  --background-dark: #2d3548;
  --text-light: rgba(255,255,255,0.6);
  --text-lighter: rgba(255,255,255,0.9);
  --spacing-s: 8px;
  --spacing-m: 16px;
  --spacing-l: 24px;
  --spacing-xl: 32px;
  --spacing-xxl: 64px;
  --width-container: 1200px;
}


.h_test {
  font-weight: 600;
  color: var(--main-color);
}
/* Footer Designs  */

/*************************************************************************/

/*footer*/
/*=-footer-=*/
footer {
  color: rgb(2, 2, 2);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}
footer p {
  color: rgb(0, 0, 0);
}
footer a {
  color: rgb(0, 0, 0);
}
.social-pet li {
  display: inline-block;
  margin-right: 10px;
}
.social-pet li a {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  text-align: center;
  display: block;
  line-height: 35px;
  background-color: #3a5a95;
  color: rgb(0, 0, 0);
}
.social-pet li:nth-child(2) a {
  background-color: #57aced;
}
.social-pet li:nth-child(3) a {
  background-color: #dd4f43;
}
.social-pet li:nth-child(4) a {
  background-color: #e63a55;
}
.social-pet li a:hover {
  background-color: #0141a2;
}
.social-pet li a:hover i {
  transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
}
.recent-post li {
  display: block;
  color: rgb(0, 0, 0);
  margin-bottom: 25px;
}
.recent-post li label {
  float: left;
  border: 2px solid rgb(0, 0, 0);
  padding: 1px 7px;
  text-align: center;
}
.recent-post li label span {
  color: rgb(0, 0, 0);
}
footer .input-group-addon {
  background-color: #0141a2;
  padding: 10px;
}
.f-address li {
  display: inline-block;
}
.f-address li i {
  color: #2995de;
  font-size: 18px;
}
.f-address li a {
  color: rgb(0, 0, 0);
}
/*=-Copyright-=*/
.copyright {
  background-color: #111;
  padding: 12px 0;
  font-size: 14px;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 88px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  /* box-shadow: 2px 2px 3px #999; */
  z-index: 100;
}

.float:hover {
  color: red;
}

.my-float {
  margin-top: 16px;
}

#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  left: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

#myBtn:hover {
  background-color: #555;
}

/* 
***********************************************************************************************************************
simple box 
*************************************************************************************************************************
  */

.smpl_c {
  background-color: hsl(44deg 100% 32% / 73%);
  height: 475px;
  position: relative;
  overflow: hidden;
  z-index: -1;
}

.smpl_c .ico1 {
  opacity: 0.1;
  margin-left: 53%;
  margin-top: -24%;
  transform: rotate(34deg);
}
.smpl_c .ico2 {
  opacity: 0.1;
  margin-left: -9%;
  margin-top: -18%;
  transform: rotate(11deg);
  width: 750px;
}

.smpl_c .sml_t {
  z-index: -1;
  color: white;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.smpl_c .sml_t span {
  font-size: 20px;
}

.docs_c {
  margin-top: -84px;
}

.d_box {
  width: 100%;
  height: 315px;
  -webkit-box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  border-radius: 8px;
  margin-bottom: 120px;
  background: rgb(255 255 255);
}

.d_box:hover {
  box-shadow: 0 0 3px black;
  margin-top: -5px;
  opacity: 1;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}

.d_box .db_top {
  float: right;
  padding: 17px;
  background: var(--main-color);
  border-radius: 0 0 0 25px;
}

.d_box .db_icon {
  padding-left: 30px;
  font-size: 96px;
  margin-top: 25px;
  text-align: center;
  color: var(--main-color);
}

.d_box span {
  font-size: 25px;
  font-weight: 600;
  color: white;
}

.d_box h5 {
  text-align: center;
  font-weight: 600;
}

.d_box p {
  text-align: center;
  font-weight: 600;
}

/************************************************************************************************
 expanding box 
***********************************************************************************************/

.exp-p {
  display: flex;
  width: 95vw;
}

.panel {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 80vh;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  flex: 0.5;
  margin: 10px;
  position: relative;
  transition: flex 0.7s ease-in;
}

.panel h3 {
  font-size: 20px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  margin: 0;
  opacity: 0;
}

.panel.active {
  flex: 5;
}

.panel.active h3 {
  opacity: 1;
  transition: opacity 0.3s ease-in 0.5s;
}

@media (max-width: 480px) {
  .container {
    width: 100vw;
  }

  .panel:nth-of-type(4),
  .panel:nth-of-type(5) {
    display: none;
  }
}

/* photography div */

.ph-div {
  background-color: var(--main-color);
  /* height: 475px; */
}
.ph-div .ico1 {
  opacity: 0.1;
  margin-left: -54%;
  margin-top: -54%;
  transform: rotate(-13deg);
  position: absolute;
}

/* .ph-div .phtext{
    text-align: center;
    padding-top: 189px;
    font-weight: 800;
} */
.ph-div p {
  font-size: 20px;
  text-align: left;
  font-weight: 600;
  font-family: var(--second-fonts);
  font-style: italic;
}

.ph-div h3 {
  font-weight: 600;
}

.ph-div .im_div {
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  margin: 24px;
  border-radius: 8px;
  background-color: #fff;
}
.ph-div .im_div:hover {
  box-shadow: 0 8px 24px rgb(0 0 0 / 30%);
}
.ph-div img {
  width: 100%;
  padding: 15px;
}

/* Welcome div  */

.d_welcome {
  text-align: center;
  margin-top: 125px;
  margin-bottom: 125px;
  /* margin: 45px; */
}

/* 
.d_welcome h2{
  font-size: 45px;
  font-weight: 600;
   font-family: 'Ephesis', cursive; 
  color: var(--main-color);
} */

.d_welcome h1 {
  font-weight: 600;
  color: var(--main-color);
  text-align: left;
}

.d_welcome span {
  color: black;
}

.d_welcome i {
  font-size: 25px;
  font-weight: 600;
}

.d_welcome .img1 {
  width: 100%;
  height: 350px;
  object-fit: cover;
  padding: 25px;
}
.d_welcome .img2 {
  width: 100%;
  height: 550px;
  object-fit: cover;
  object-position: center;
  padding: 0 25px 25px 25px;
}
.d_welcome .dw_t {
  font-family: var(--second-fonts);
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  font-style: italic;
}

/* 
---------------------------------------------------------------
Category home page 
---------------------------------------------------------
  */

@media (min-width: 754px) {
  .img_cats {
    height: 110vh;
  }

  .img_cats {
    margin-top: 20vh;
    margin-bottom: 5vh;
  }
}

.img_cats h2 {
  font-weight: 600;
  color: var(--main-color);
}

.img_cats .d_img {
  height: 350px;
  background-color: #fff;
  margin: 20px;
  padding: 15px 15px;
  border-radius: 10px;
}
.img_cats .d_img:hover img {
  opacity: 0.5;
  padding: 10px;
  transition: 1s;
}

.img_cats img {
  height: 90%;
  width: 100%;
  object-fit: cover;
}

.img_cats .img_b {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
}
.img_cats .img_b i {
  float: right;
  margin-right: 10px;
}

/* 
---------------------------------------------------------------
Category home page 
---------------------------------------------------------
  */

@media (min-width: 1184px) {
  /* .d_bf{
      height: 110vh;
    } */

  .d_bf {
    margin-top: 20vh;
  }
  .d_bf .d_p {
    top: 30% !important;
  }
}

.d_bf h2 {
  font-weight: 600;
  color: var(--main-color);
}
.d_bf span {
  font-weight: 600;
  color: #fff;
}

.d_bf .db_img {
  height: 450px;
  background-color: #fff;
  margin: 20px;
  padding: 15px 5px 15px 15px;
  border-radius: 10px;
  position: relative;
}

.d_bf .d_p {
  color: #000000;
  position: absolute;
  top: 10px;
  padding: 30px;
  font-weight: 900;
  font-family: var(--second-fonts);
}
/* .d_bf .db_img:hover img{
  opacity: 0.5;
  padding:10px;
  transition: 1s;

} */

.d_bf img {
  height: 90%;
  width: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.d_bf .img_b {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
}
.d_bf .img_b i {
  float: right;
  margin-right: 10px;
}

/* insta feed  */

.instafeed img {
  width: 25%;
  padding: 25px;
}

/* 
---------------------------------------------------
before footer section
------------------------------------------------
  */

.b_df {
  padding: 63px;
}

.d_bf1 {
  /* padding: 8px; */
  /* background-image: url(/assets/img/suaadalsuwaidi_before_footer.jpg); */
  height: 300px;
  background-position: center;
  background-size: cover;
  opacity: 0.5;
  padding-right: 10px;
}
.d_bf2 {
  /* padding: 8px; */
  /* background-image: url(/assets/img/suaadalsuwaidi_before_footer.jpg); */
  height: 300px;
  background-position: center;
  background-size: cover;
  opacity: 0.5;
}



/* home design  */

.why-chose-us{
  height: 100%;
  width: 100%;
  object-fit: cover;
}


.wedo{
  background-color: #ddae4338;
}

.wedo img{
  height: 30vh;
  width: 100%;
  /* object-fit: cover; */
}


#contact{
  background-color: #ddae4338;
}



/* // image book  */
.hero-section{
  align-items: flex-start;
  /* background-image: linear-gradient(15deg, #0f4667 0%, #2a6973 150%); */
  display: flex;
  min-height: 100%;
  justify-content: center;
  padding: var(--spacing-xxl) var(--spacing-l);
}

.card-grid{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: var(--spacing-l);
  grid-row-gap: var(--spacing-l);
  max-width: var(--width-container);
  width: 100%;
}

@media(min-width: 540px){
  .card-grid{
    grid-template-columns: repeat(2, 1fr); 
  }
}

@media(min-width: 960px){
  .card-grid{
    grid-template-columns: repeat(4, 1fr); 
  }
}

.card_cat{
  list-style: none;
  position: relative;
}

.card_cat:before{
  content: '';
  display: block;
  padding-bottom: 150%;
  width: 100%;
}

.card__background{
  background-size: cover;
  background-position: center;
  border-radius: var(--spacing-l);
  bottom: 0;
  filter: brightness(0.75) saturate(1.2) contrast(0.85);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center;
  trsnsform: scale(1) translateZ(0);
  transition: 
    filter 200ms linear,
    transform 200ms linear;
}

.card_cat:hover .card__background{
  transform: scale(1.05) translateZ(0);
}

.card-grid:hover > .card_cat:not(:hover) .card__background{
  filter: brightness(0.5) saturate(0) contrast(1.2) blur(20px);
}

.card__content{
  left: 0;
  padding: var(--spacing-l);
  position: absolute;
  top: 0;
}

.card__category{
  color: var(--text-light);
  font-size: 0.9rem;
  margin-bottom: var(--spacing-s);
  text-transform: uppercase;
}

.card__heading{
  color: var(--text-lighter);
  font-size: 1.9rem;
  text-shadow: 2px 2px 20px rgba(0,0,0,0.2);
  line-height: 1.4;
  word-spacing: 100vw;
}


@import url(https://fonts.googleapis.com/css?family=Raleway:400,200,300,800);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
figure.snip0056 {
  font-family: 'Raleway', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  /* min-width: 380px;
  max-width: 480px; */
  width: 100%;
  height: 390px;
  background: #ffffff;
  color: #000000;
}
figure.snip0056 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
figure.snip0056 > img {
  height: 60%!important;
  width: 50%;
  border-radius: 50%;
  border: 4px solid #ffffff;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  -webkit-transform: scale(1.6);
  transform: scale(1.6);
  position: relative;
  float: right;
  right: -15%;
  z-index: 1;
  height: 17vw;
}
figure.snip0056 figcaption {
  padding: 20px 30px 20px 20px;
  position: absolute;
  left: 0;
  width: 50%;
}
figure.snip0056 figcaption h2,
figure.snip0056 figcaption p {
  margin: 0;
  text-align: left;
  padding: 10px 0;
  width: 100%;
}
figure.snip0056 figcaption h2 {
  font-size: 1.3em;
  font-weight: 300;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
figure.snip0056 figcaption h2 span {
  font-weight: 800;
  color: var(--main-color);
}
figure.snip0056 figcaption p {
  font-size: 0.9em;
  opacity: 0.8;
}
figure.snip0056 figcaption .icons {
  width: 100%;
  text-align: left;
}
figure.snip0056 figcaption .icons i {
  font-size: 26px;
  padding: 5px;
  top: 50%;
  color: #000000;
}
figure.snip0056 figcaption a {
  opacity: 0.3;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}
figure.snip0056 figcaption a:hover {
  opacity: 0.8;
}
figure.snip0056 .position {
  width: 100%;
  text-align: left;
  padding: 15px 30px;
  font-size: 0.9em;
  opacity: 1;
  font-style: italic;
  color: #ffffff;
  background: #000000;
  clear: both;
}
figure.snip0056.blue .position {
  background: #20638f;
}
figure.snip0056.red .position {
  background: #962d22;
}
figure.snip0056.yellow .position {
  background: #bf6516;
}
figure.snip0056:hover > img,
figure.snip0056.hover > img {
  right: -12%;
}


/* Demo purposes only */
/* html {
  height: 100%;
}
body {
  background-color: #212121;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  margin: 0;
  height: 100%;
} */



.wedo h2, .wedo h4{
  color:var(--main-color);
}

#whychose h2{
  color: var(--main-color);
}


#contact h5{
  color: var(--main-color);
}



@media only screen and (min-width: 900px) {
  .m_content .mc_t {
    padding-top: 1rem;
  }

  /* .top-bottom{
    margin-left: 138px;
    margin-top: -85px;
  } */
}

.m_content {
  margin-top: 120px;
}
.m_content h3{
  color: var(--main-color);
  font-weight: 700;
}

.m_content .m_box {
  width: 100%;
  padding: 20px;
}
.m_content .m_box .mb_c {
  background-color: var(--main-color);
  border-radius: 10px;
}

.m_content .m_box img {
  margin: -10px 0 15px 15px;
  width: 100%;
  object-fit: cover;
}

.m_content .m_t p {
  color: var(--main-color);
}

.about_text{
  font-weight: 600;
}




/* navbar top  */


.navbar {
  background-color: #eeeeee;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 100%;
  direction: ltr;
}

.main-container{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color:rgba(128, 255, 255, 0.3);
}

.right-nav-logo {
  color: #f5b921;
  align-items: center;
  margin-left: 47%;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
}

.right-nav-logo img{
  margin-top: -9px;
  width: 260px;
  height: 100%;

}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-links {
  color: #986f2f;
  text-decoration: none;
  /*padding: 0.5rem 1rem; */
  height: 100%;
  border-bottom: 3px solid transparent;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #986f2f;
}
.nav-links:hover {
  color: #986f2f;
}

.nav-item.active {
  color: #ffdd40;
  border: 1px solid #ffdd40;
}

.nav-icon {
  display: none;
}


@media screen and (max-width: 963px) {
  .right-nav-logo{
    margin-left: 20px;
  }
}

@media screen and (min-width: 1507px) {
  .right-nav-logo{
    margin-left: 30%;
  }
}
@media screen and (max-width: 960px) {
  
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #000000;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #ffffff;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #000000;
  }
  .nav-icon i{
      font-size: 50px;
      margin-top: -12px;
  }

  .right-nav-logo img {
      margin-top: -15px;
      width: 75%;
  }
}

.navbar-dark .navbar-nav .nav-link.active{
  color: #000000;
}

.navigation {
  position: relative;
  display: inline-block;
}

.navigation-content {
  margin-top: 5px;
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  border-radius: 3px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  margin-left: -15px;
}

.navigation:hover .navigation-content {
  display: block;
}

.navigation-content .link:hover {
  background-color: antiquewhite;
}

.navigation a {
  color: black;
  padding: 12px;
  margin: 0;
  text-decoration: none;
  display: block;
}

.nav-logo {
  color: #f5b921;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
}

.nav-logo img{
  margin-top: -9px;
  width: 260px;
  height: 100%;

}




