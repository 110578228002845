.en_sc1 img ,.en_sc2 img ,.en_sc3 img ,.en_sc4 img ,.en_sc5 , .en_sc6 img {
    transform: rotate(15deg); 
}

.en_sc_sr h2{
    font-weight: 600;
}

.en_sc_sr p{
    color: #b8860b;
}
.en_sc_sr span{
    color: white;
    font-weight: 700;
}