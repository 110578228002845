.slider {
    height: 60vh;
    width: 100%;
    margin: 0 auto;
  }
  
  .slider-container {
    /* width: 100vw; */
    height: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    display: flex;
  }
  
  header {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  header.activeSlide {
    opacity: 1;
    transform: translateX(0);
    flex: 1;
  }
  
  header.prevSlide {
    transform: translateX(-200%);
    opacity: 0;
    flex: 0;
  }
  
  header.nextSlide {
    transform: translateX(200%);
    opacity: 0;
    flex: 0;
  }
  
  header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .sbtn {
    position: absolute;
    top: 40%;
    height: 120px;
    width: 25px;
    border: none;
    cursor: pointer;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.3s ease;
    border-radius: 6px;
  }
  
  .sbtn.next {
    right: 0;
  }
  
  .sbtn.prev {
    left: 0x;
  }
  
  .sbtn:hover,
  .sbtn:active {
    background-color: #fff;
    color: black;
    scale: 0.9;
  }
  
  article {
    position: absolute;
    padding: 30px;
    width: 600px;
    background-color: rgba(0, 0, 0, 0.7);
    bottom: 4%;
    right: 2%;
    border-radius: 10px;
    color: #fff;
  }
  
  .title {
    font-size: 36px;
    text-transform: uppercase;
    letter-spacing: 6px;
    font-weight: 400;
    margin-bottom: 10px;
    color: var(--main-color);
  }
  
  .info {
    line-height: 140%;
    font-weight: lighter;
    letter-spacing: 1px;
  }
  
  .progressBtn-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 100px;
    background-color: rgb(0, 0, 0);
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .progressBtn {
    width: 15px;
    height: 15px;
    /* border: none; */
    background: #fff;
    border-radius: 50%;
  }
  